@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap');
@import url("settings.css");
@import url("utilities.css");
@import url("color.css");

body,
body.bg-app {
    background-color: #f8f7fa;
}

body.bg-auth {
    height: 100vh;
    background-image: url(../images/background-3.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

a {
    text-decoration: none;
}

.text-left { text-align: left; }
.text-right { text-align: right; }

.btn,
.form-control {
    border-radius: 0;
}

.form-control {
    border: none;
    background: #fafafa;
    border: solid 1px #f1f0f0;
}

label.form-label {
    font-size: 13px;
    font-weight: 500;
}

.card {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
}

.dashCard {
    border: none;
    /* border-radius: 0.6rem; */
    height: 7rem;
    margin: 5px;
    position: relative;
    /* z-index: -100; */
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .12);
    transition: all 0.2s;
    overflow: hidden;
}
.dashCard:hover {
    box-shadow: none;
}
.cursor-pointer { cursor: pointer;}

.text-bold-400 { font-weight: 400; }
.text-bold-500 { font-weight: 500; }
.text-bold-600 { font-weight: 600; }
.text-bold-700 { font-weight: 700; }

/***************************************
***** AUTH FORMS, login, register, forgot password
***************************************/
.centerForm {
    width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 25px 0;
}

.authForm {
    padding: 25px;
    background: rgba(255, 255, 255, 0.7);
    border: solid 1px rgba(255, 255, 255, 0.7);
}

.remember {
    display: flex;
    justify-content: space-between;
}

.authForm .form-control {
    padding: 14px 18px;
}

.authForm .btn {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Dark Mode */
/* .darkMode .authForm {
    color: #fff !important;
} */

.darkMode .authForm {
    padding: 25px;
    background: rgb(0 0 0 / 50%);
    border: solid 1px rgb(0 0 0 / 50%);
}

/* .darkMode p {
    color: #fff;
} */

.darkMode .autocomplete-dropdown-container .loading {
    color: #fff;
}

/***************************************
***** SIDEBAR
***************************************/
.layout-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 16.25rem;
    color: #fff;
}

.logo-container {
    display: flex;
    align-items: center;
    min-height: 56px;
    padding: 0 0 0 12px;
}

.layout-menu .logo {
    width: 200px;
    padding: 0.1rem 0.2rem;
    margin-right: 8px;
}

.logo-text {
    margin: 0;
    font-size: 20px;
    color: #fff;
}

.layout-menu button.menu-toggle {
    position: absolute;
    right: 0;
    top: 0;
    border-style: solid;
    border-width: 2px;
    margin: 15px -40px 0 0;

    font-size: 11px;
    width: 22px;
    height: 22px;
    text-align: center;
    padding: 0;
}

.layout-menu-fixed .layout-menu button.menu-toggle {
    margin: 16px -10px 0 0;
}

.menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-item a {
    padding: 10px 14px;
    text-decoration: none;
    display: flex;
    color: #fff;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
}

.menu-item a .icon {
    width: 18px;
    margin-right: 0.5rem;
}

.menu-item a img,
.menu-item a svg {
    width: 15px;
    margin-right: 0.6rem;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
.layout-page {
    margin-left: 0;
    padding-top: 65px !important;
    padding: 1px 16px 20px;
    /* height: 1000px; */
}

.layout-menu-fixed .layout-page {
    margin-left: 16.25rem;
}

/***************************************
***** TOPBAR
***************************************/
.layout-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto 0 0;
    border-radius: 0.375rem;
    padding: 0;
    z-index: 500;
}

.layout-menu-fixed .layout-navbar {
    left: 16.25rem;
    margin-left: 0;
    z-index: 6;
}

/* .layout-menu {
    transition: width 0.3s;
} */

/* @media only screen and (max-width: 768px) { */
.layout-menu {
    transform: translateX(-100%);
    z-index: 501;
}

.layout-menu-fixed .layout-menu {
    transform: translateX(0);
    z-index: 10;
}

.layout-navbar-wrapper {
    background: #fff;
    padding: 0 20px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
    .layout-menu {
        transform: translateX(-100%);
        z-index: 11;
    }

    .layout-menu-fixed .layout-menu {
        transform: translateX(0);
        z-index: 10;
    }

    .layout-menu-fixed.device .layout-page,
    .layout-menu-fixed.device .layout-navbar {
        margin-left: 0;
        left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .pageTitle .btn {
        font-size: 11px !important;
        padding: 4px 8px;
    }
    .pageTitle .btn svg {
        width: 11px !important;
    }
}

/* 
USER DROPDOWN
*/
.userDropdown .dropdown-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 14px;
}

.userDropdown .dropdown-toggle img {
    width: 40px;
    border-radius: 100%;
}

.userDropdown .dropdown-toggle::after {
    display: none;
}

.userDropdown ul.dropdown-menu.show {
    border: none;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
    border-radius: 0;
    margin-top: 6px !important;
    padding: 0;
}

.userDropdown .userInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.userDropdown .userInfo span {
    font-size: 13px;
}

.userDropdown .avatar {
    width: 40px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
}

.userDropdown .avatar img {
    width: 40px;
}

.userDropdown .dropdown-item {
    padding: 12px 20px;
    display: flex;
    align-items: center;
}

.userDropdown .dropdown-item svg {
    width: 15px;
    margin-right: 7px;
}

.userDropdown li {
    border-bottom: solid 1px #fafafa;
}

/***************************************
***** LOADING
***************************************/
.loadingCard .card-body {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/***************************************
***** BADGE
***************************************/
.table .badge {
    padding: 5px 11px;
    line-height: 12px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
}

/***************************************
***** AVATAR
***************************************/
.shortAvatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.avatarSm {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
}

.avatarSm img {
    width: 100%;
}

.avatarSm img:before {
    content: attr(alt);
    width: 100%;
}

/***************************************
***** PAGE TITLE
***************************************/
.pageTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.pageTitle .page-heading {
    font-size: 20px;
    margin-bottom: 0;
}

.pageTitle nav {
    font-size: 13px;
}

.pageTitle .breadcrumb {
    margin-bottom: 0;
}

.pageTitle .btn {
    font-size: 14px;
    font-weight: 600;
}

.pageTitle .btn svg {
    width: 20px;
}

/***************************************
***** MODAL
***************************************/
.modal-content {
    border: none;
}

.modal-header {
    background: #007bfc;
    color: #fff;
}

.modal-header,
.modal-content,
.modal-footer {
    border-radius: 0;
}

.modal-header button.btnClose {
    background: none;
    color: #fff;
    padding-top: 3px;
    opacity: 0.7;
}

.modal-header button.btnClose:hover {
    opacity: 1;
}

.modal-content .modal-body button.btnClose {
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2)!important;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: -12px;
    opacity: 1;
    background: #007bfc!important;
    border: 0;
    width: 25px;
    height: 25px;
    padding: 0;

    /* background: none;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    margin: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px; */
}

.modal-footer {
    background: #fafafa;
    border-color: #f5f2f2;
}

.modal-footer .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 9px 20px;
}

/***************************************
***** SUBSCRIPTION
***************************************/
.planA {
    padding: 10px 20px;
}

.planA .name {
    font-size: 24px;
    margin: 0 0 10px;
}

.planA .price .amount {
    font-size: 58px;
    font-weight: 700;
    line-height: 56px;
}

.planA .price .sign {
    font-size: 23px;
}

.planA .price {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: -5px;
}

.planA .shortDescription {
    font-size: 13px;
    color: #999;
}

.timePeriod {
    margin: 10px 0 20px;
    font-weight: 500;
}

.planA .btn {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 8px 25px;
}

/***************************************
***** SWEET ALERTS
***************************************/
.swal2-actions {
    flex-direction: row-reverse;
}

.swal2-actions .swal2-styled {
    border-radius: 0;
    border-style: solid !important;
    border-width: 1px !important;
}
.swal2-cancel {
    background: none !important;
}

/***************************************
***** TABLE
***************************************/
.customTableSearch .form-control {
    background-color: #fafafa;
    color: #222829;
    transition: color .2s ease, background-color .2s ease;
    padding: 10px 0 10px 40px;
}

.customTableSearch .icon {
    position: absolute;
    left: 0;
    top: 0;
    margin: 10px 0 0 12px;
}

.customTableSearch .icon svg {
    width: 18px;
}

.table-sort-asc:after,
.table-sort-desc:after {
    opacity: 1;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: initial;
}

.table-sort-asc:after {
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='%23222829'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.421 2.065a.587.587 0 00-.635.064l-3.609 3a.47.47 0 00-.032.706c.206.205.554.22.777.035l3.22-2.676 2.922 2.65a.589.589 0 00.777-.001.469.469 0 00-.001-.707L4.544 2.148a.53.53 0 00-.123-.083z'/%3E%3C/svg%3E);
}

.table-sort-desc:after {
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='%23222829'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.421 5.935a.587.587 0 01-.635-.064l-3.609-3a.47.47 0 01-.032-.706.589.589 0 01.777-.035l3.22 2.676 2.922-2.65a.589.589 0 01.777.001.469.469 0 01-.001.707L4.544 5.852a.53.53 0 01-.123.083z'/%3E%3C/svg%3E);
}

.table-sort-asc:after,
.table-sort-desc:after,
.table-sort:after {
    position: relative;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    content: " ";
    bottom: auto;
    right: auto;
    left: auto;
    margin-left: 0.5rem;
}

.table td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    vertical-align: middle;
}

.table th {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.table tr {
    border-bottom: 1px solid #fafafa;
}

.actionButtons {
    display: flex;
    justify-content: center;
}

.actionButtons .btn {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actionButtons .btn svg {
    width: 15px;
}

.table tr th:last-child {
    text-align: center;
}

/***************************************
***** ADDRESS DROPDOWN
***************************************/
.addressDropdown input {
    margin-bottom: 1px;
}
.addressDropdown li li {
    padding: 3px 10px;
    background: #fafafa;
    border-bottom: solid 1px #fff;
    font-size: 13px;
    cursor: pointer;
}
.addressDropdown li:hover {
    background: #eee;
}


.autocomplete-dropdown-container {
    padding: 0;
    margin: 0;
}
.autocomplete-dropdown-container li {
    padding: 4px 10px;
    font-size: 13px;
    list-style: none;
    text-align: left;
}

.btnToLink {
    background: none;
    color: #007bfc;
    padding: 0;
    box-shadow: none;
    border: none;
}

.dropDownRemove{
    /* border: none; */
    /* cursor: none !important; */
    appearance: none;
	padding: 5px;
    display: flex;
    /* justify-content: space-between; */
    width: 150px;
	/* background-color: #4834d4; */
	/* color: white; */
	border: none;
	font-family: inherit;
	outline: none;
}

/* toggle button css */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0px 5px;
}

/* / Hide default HTML checkbox / */
.switch input {
    opacity: 0;
    width: 0;
    height: 0px;
}

/* / The slider / */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider::before {
    position: absolute;
    content: "";
    height: 19px;
    width: 21px;
    left: 0px;
    bottom: 01px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* / Rounded sliders / */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.locationSearchContainer {
    position: relative;
}
.locationSearchContainer .loading {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}



.updateAddress {
    position: relative;
}
.updateAddress svg.editIcon {
    color: #000;
    width: 13px;
    position: absolute;
    top: 0;
    right: 0;
    margin: -8px -16px 0 0;
}
.updateAddress svg.mapPinIcon {
    width: 16px;
}







/* 
    Input placeholder move to top
*/
.input-container {
    position: relative;
  }
  
  .input-label {
    position: absolute;
    top: 10px;
    left: 20px;
    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  }
  
  .input-label--focused {
    top: 0;
  }
  



/* 
  subscriptionModal
*/
.modal-header button.btnClose {
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,.2)!important;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: -12px;
    opacity: 1;
    background: #007bfc;
    border: 0;
    width: 25px;
    height: 25px;
    padding: 0;
}
.subscriptionModal .modal-header .btn-close {
    display: none;
}
.subscriptionModal .modal-content {
    background: none;
}
.subscriptionModal .modal-body {
    background: #fff;
}
.bblr {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.bbrr {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.subscriptionModal .modal-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
}
.subscriptionModal .modal-title {
    font-family: 'Oswald', sans-serif;
    font-size: 2.5rem;
    text-align: center;
}
.subscriptionCarImage img {
    width: 100%;
}

/* 
    SubscriptionPrice
*/
.subscriptionPrice {
    font-family: 'Oswald', sans-serif;
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 1;
    position: relative;
}
.subscriptionPrice .text {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    line-height: 54px;
    margin-bottom: 32px;
    margin-right: -20px;
}
.subscriptionPrice .text span:first-child {
    font-size: 46px;
    text-transform: uppercase;
    font-weight: 500;
}
.subscriptionPrice .text span:last-child {
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 7px;
    margin-right: -8px;
}
.subscriptionPrice .price {
    font-size: 188px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 220px;
    color: #093e80;
}
.priceWithImage .subscriptionCarImage {
    margin-top: -50px;
    z-index: 2;
    position: relative;
}

@media only screen and (max-width: 1200px) {
    body.bg-auth {
        background-size: auto;
    }
}

@media only screen and (max-width: 600px) {
    body.bg-auth {
        background-size: auto;
    }
    .centerForm {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .subscriptionPrice .price {
        font-size: 130px;
        line-height: 170px;
    }
    .subscriptionPrice .text span:first-child {
        font-size: 33px;
    }
    .subscriptionPrice .text span:last-child {
        font-size: 48px;
    }
    .subscriptionPrice .text {
        line-height: 41px;
        margin-right: -12px;
    }
}
@media only screen and (max-width: 360px) {
    .remember {
        flex-direction: column;
    }
    .forgot_password {
        margin-left: 0 !important;
    }
}

  
  .dashIconUp{
    /* height: 100px; */
    border-radius: 0 0 50% 50%;
    position: relative;
    z-index: 5;
    font-size: 5px;
    top: -1rem;
    padding: 10px;
    color: #fff;
    box-shadow: 0px 2px 5px 0px #2228293b;
  }

  .dashIcon{
    /* height: 100px; */
    border-radius: 0 50% 50% 0;
    /* margin-left: -16px; */
    position: relative;
    z-index: 5;
    left: -16px;
    top: 1.1rem;
    padding: 10px;
    color: #fff;
    box-shadow: 0px 2px 10px 0px #2228293b;
  }

  .bgdashCard{
    top: 40%;
    left: 5%;
    width: 60%;
    scale: 4;
    rotate: 20deg;
    position: absolute;
    z-index: 5;
    overflow: hidden;
    opacity: 0.04;
    color: inherit;
    text-align: left;
  }
  .dashCardHeading{
    font-size: 2.5rem;
    position: absolute;
    color: inherit;
    right: 5px;
    bottom: 25%;
  }
  .dashCardSpan{
    display: flex;
    align-items: baseline;
    position: absolute;
    bottom: 2%;
    right: 5px;
    font-size: 0.9rem;
    font-family: inherit;

  }
  .userImgDummy{
    background: #a1a1a1;
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #efefef;
    margin: auto;
  }
  .bgcolorRed{
    background-color: #f35532;
  }
  .bgcolorBlue{
    background-color: #56ecf7;
  }
  .bgcolorBlueDark{
    background-color: #007bfc;
  }
  .bgcolorPurple{
    background-color: #5a69ec;
  }
  .bgcolorPurpleDark {
    background-color: #6437ec; /* dark purple */
  }
  .colorWhite{
    background-color: #fff;
  }

  .colorRed{
    color: #f1b04c;
  }
  .colorBlue{
    color: #56ecf7;
  }
  .colorBlueDark{
    color: #007bfc;
  }
  .colorPurple{
    color: #5a69ec;
  }
  .colorPurpleDark {
    color: #6437ec; /* dark purple */
  }

  .userImgDummy-A, .bg-A {
    background-color: #ffda79;
  }
  .userImgDummy-B, .bg-B {
    background-color: #b7e1cd;
  }
  .userImgDummy-C, .bg-C {
    background-color: #ff859f;
  }
  .userImgDummy-D, .bg-D {
    background-color: #7bcecc;
  }
  .userImgDummy-E, .bg-E {
    background-color: #ffa8c5;
  }
  .userImgDummy-F, .bg-F {
    background-color: #6bb9d6;
  }
  .userImgDummy-G, .bg-G {
    background-color: #ffc28a;
  }
  .userImgDummy-H, .bg-H {
    background-color: #9ac4c4;
  }
  .userImgDummy-I, .bg-I {
    background-color: #ffb4c1;
  }
  .userImgDummy-J, .bg-J {
    background-color: #a0ced9;
  }
  .userImgDummy-K, .bg-K {
    background-color: #ff7f7f;
  }
  .userImgDummy-L, .bg-L {
    background-color: #5db5a4;
  }
  .userImgDummy-M, .bg-M {
    background-color: #ffd7a5;
  }
  .userImgDummy-N, .bg-N {
    background-color: #ff7f50;
  }
  .userImgDummy-O, .bg-O {
    background-color: #87cefa;
  }
  .userImgDummy-P, .bg-P {
    background-color: #f08080;
  }
  .userImgDummy-Q, .bg-Q {
    background-color: #c1ffc1;
  }
  .userImgDummy-R, .bg-R {
    background-color: #ffb347;
  }
  .userImgDummy-S, .bg-S {
    background-color: #a0d1a0;
  }
  .userImgDummy-T, .bg-T {
    background-color: #92fe9b;
  }
  .userImgDummy-U, .bg-U {
    background-color: #afeeee;
  }
  .userImgDummy-V, .bg-V {
    background-color: #d8bfd8;
  }
  .userImgDummy-W, .bg-W {
    background-color: #ba55d3;
  }
  .userImgDummy-X, .bg-X {
    background-color: #ffc0cb;
  }
  .userImgDummy-Y, .bg-Y {
    background-color: #f5deb3;
  }
  .userImgDummy-Z, .bg-Z {
    background-color: #ffa07a;
  }
  .dropIcon{
    color: #1c246c;
    /* z-index: 12; */
    position: relative;
    right: 1rem;
  }
  
  

.user-avatar {
    padding: 3px;
    position: relative;
    border: solid 4px #fafafa;
}
.file-input {
    display: none; /* hide the default file input button */
}
.file-label {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    cursor: pointer;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: -3px;
}
.file-label svg {
    color: #fff;
    width: 17px;
}
.hide-btn-close .btn-close {
    display: none;
}


.sortingicon{
    scale: 0.7;
    color: #222829;
}

.dataTable tr th:last-child {
    text-align: center;
}
.carAvatarSm {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carAvatarSm img {
    height: 100%;
    object-fit: cover;
}


.whiteCover{
    height: 40vh;
    width: 80%;
    position: absolute;
    z-index: 999;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.avatarSm .symbol-circle {
    width: 100%!important;
    height: 100%!important;
    display: flex;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 0.475rem;
}
.avatarSm .symbol-circle:before {
    content: attr(alt);
    width: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase!important;
    color: #fff!important;
    font-weight: 600!important;
}

.user-profile-avatar {
    width: 150px;
    height: 150px;
    /* overflow: hidden; */
    position: relative;
    border: solid 4px #fafafa;
    border-radius: 100%;
    margin: 0 auto;
}
.user-profile-avatar .symbol-circle {
    width: auto!important;
    height: 100%!important;
    display: flex;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
}
.user-profile-avatar .innerContainer {
    width: 142px;
    height: 142px;
    overflow: hidden;
    border-radius: 100%;
}
.user-profile-avatar .symbol-circle:before {
    content: attr(alt);
    width: 150px;
    line-height: 136px;
    text-align: center;
    text-transform: uppercase!important;
    color: #fff!important;
    font-weight: 600!important;
    font-size: 50px;
}
.user-profile-avatar .file-input {
    display: none; /* hide the default file input button */
}
.user-profile-avatar .file-label {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    cursor: pointer;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 9px;
    border-radius: 100%;
}
.user-profile-avatar .file-label svg {
    color: #fff;
    width: 15px;
}

.carPhotosGallery .carPhoto {
    float: left;
    width: 48%;
    margin: 1%;
}

.fancybox__container {
    z-index: 1056 !important;
}

a.fancyBoxGalleryItem {
    float: left;
    margin: 1%;
}
a.fancyBoxGalleryItem img {
    width: 100%;
}




.gancyBoxGallery .featuredImage {
    width: 98% !important;
}
.fancyBoxGallery .featuredImage img {
    object-fit: cover;
    height: 200px;
    width: 100%;
}

.fancyBoxGallery .galleryScroll {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
}
.fancyBoxGallery .galleryItem {
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: middle;
}
.fancyBoxGallery .galleryItem:last-child {
    margin-right: 0;
}
.fancyBoxGallery .galleryItem img {
    height: 60px;
    width: 90px;
    object-fit: cover;
}

/*  */
/* width */
.galleryScroll::-webkit-scrollbar {
    height: 5px;
}
  
  /* Track */
.galleryScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
.galleryScroll::-webkit-scrollbar-thumb {
    background: #a8a8a8;
}
  
  /* Handle on hover */
.galleryScroll::-webkit-scrollbar-thumb:hover {
    background: #777777;
}
/*  */



/* 
    Input Icon Inner Pages
*/
.innerInputIcon {
    position: relative;
}
.innerInputIcon .innerInputIcons {
    position: absolute;
    right: 0;
    top: 0;
}
.innerInputIcon .innerInputIcons span {
    cursor: pointer;
    background: none;
    padding: 6px 12px 6px 12px;
    display: inline-block;
}
.innerInputIcon .innerInputIcons span svg {
    width: 17px;
}
/* 
    Input Icon
*/
.inputIcon {
    position: relative;
}
.inputIcon .inputIcons {
    position: absolute;
    right: 0;
    top: 0;
}
.inputIcon .inputIcons span {
    cursor: pointer;
    background: none;
    padding: 14px;
    display: inline-block;
}




.form-control .form-select {
    background-color: #fafafa;
}


.spinner-border.smBtn {
    width: 13px;
    height: 13px;
    border-width: 2px;
}




/* 
    Customer Detail Header
*/
.detailHeader .avatar {
    width: 150px;
    height: 150px;
    /* overflow: hidden; */
    position: relative;
    border: solid 4px #fafafa;
    border-radius: 100%;
    margin: 0;
}
.detailHeader .avatar .symbol-circle {
    width: auto!important;
    height: 100%!important;
    display: flex;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
}
.detailHeader .avatar .innerContainer {
    width: 142px;
    height: 142px;
    overflow: hidden;
    border-radius: 100%;
}
.detailHeader .avatar .symbol-circle:before {
    content: attr(alt);
    width: 150px;
    line-height: 136px;
    text-align: center;
    text-transform: uppercase!important;
    color: #fff!important;
    font-weight: 600!important;
    font-size: 50px;
}

.detailHeader {
    display: flex;
    align-items: center;
}
.detailHeader .leftSide {
    display: flex;
    align-items: center;
    padding-right: 24px;
}
.detailHeader .rightSide {
    flex-grow: 1;
}

.countersData {
    display: flex;
    text-align: center;
}
.countersData .count {
    width: 106px;
    border: dashed 1px #fff;
    margin-right: 10px;
    padding: 5px 10px;
    background: #f8f7fa;
}
.countersData .count .number {
    font-weight: 500;
    font-size: 18px;
}

.detailHeader .status {
    display: flex;
    align-items: center;
}
.detailHeader .status span.badge {
    font-weight: 400;
    font-size: 12px !important;
    margin-left: 10px;
}

input[type="password"]::-ms-reveal {
    display: none;
}